import request from '@/utils/request'


// 查询话题列表
export function listTopic(query) {
  return request({
    url: '/video/topic/list',
    method: 'get',
    params: query
  })
}

// 查询话题分页
export function pageTopic(query) {
  return request({
    url: '/video/topic/page',
    method: 'get',
    params: query
  })
}

// 查询话题详细
export function getTopic(data) {
  return request({
    url: '/video/topic/detail',
    method: 'get',
    params: data
  })
}

// 新增话题
export function addTopic(data) {
  return request({
    url: '/video/topic/add',
    method: 'post',
    data: data
  })
}

// 修改话题
export function updateTopic(data) {
  return request({
    url: '/video/topic/edit',
    method: 'post',
    data: data
  })
}

// 删除话题
export function delTopic(data) {
  return request({
    url: '/video/topic/delete',
    method: 'post',
    data: data
  })
}
